import axios from 'axios'

const token = localStorage.getItem('user-token')
const headersFormData = {headers: {"Content-Type": `multipart/form-data;`, 'Authorization': `${token}`}}
const headers= {headers: {'Authorization': `${token}`}}
const BASE_API = process.env.VUE_APP_BASE_API
const name = 'bankEmployees'

export default {

    // create(data){
    //     return axios.post(`${BASE_API}/${name}/create`,data,headers)
    // },
    get(data){
        return axios.post(`${BASE_API}/${name}/`,data)
    },
    // update(id,data){
    //     return axios.post(`${BASE_API}/${name}/update/${id}`,data,headers)
    // },
    // delete(id){
    //     return axios.post(`${BASE_API}/${name}/destroy/${id}`,{},headers)
    // }

} 



