<template>
  <v-app class="login">
    <v-spacer />

    <v-container class="d-flex py-0 pa-0 justify-center align-center">
      <v-col class="pa-0" sm="12" md="12" lg="9" xl="6">
        <!-- =======================================================
                                Overlay
        ============================================================-->
        <v-overlay :value="overlay">
          <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
        </v-overlay>

        <!-- =======================================================
                                Snackbar
        ============================================================-->
        <v-snackbar v-model="snackbar.active">
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
          </template>
        </v-snackbar>

            <v-container fluid>
            <v-row>
              <v-col class="pt-3">
                <v-row justify="center">
                  <v-img  :src="logo" contain max-width="60" width="100%" />
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="10" lg="6" xl="6">
                <h4 class="mb-3 white--text text-center">Sindicato dos Empregados em Estabelecimentos Bancários de Uberlândia e Região</h4>
              </v-col>
            </v-row>
          </v-container>
        <v-col cols="12 pa-0 pa-sm-4">
          

          <v-card class="card">
            <v-stepper v-model="stepper">
              <v-stepper-header class="step pt-0" v-if="stepper !== 3">
                <v-spacer />
                <v-stepper-step :complete="stepper > 1" step="1">Cadastro</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="stepper > 2" step="2">Votação</v-stepper-step>
                <v-spacer />
              </v-stepper-header>

              <v-stepper-items>
                <!-- =================================================================
                                                      STEEP 1
                ======================================================================-->

                <v-stepper-content step="1">
                  <v-container cols="12" class="d-flex pa-0 justify-center align-center">
                    <v-col cols="12">
                      <!-- <h5 class="text-center mb-5 blue--text lighten-4">Dados do colaborador</h5> -->

                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <h5 class="text-start mb-1 blue--text">CPF</h5>

                          <div class="v-text-field pt-0">
                            <the-mask
                              id="cpf"
                              class="the-mask"
                              @input="formatCpf($event)"
                              v-model="cpf"
                              placeholder="999.999.999-99"
                              :mask="['###.###.###-##']"
                            />
                            <v-progress-linear v-if="cpfIsGet" indeterminate></v-progress-linear>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <h5 class="text-start mb-1 blue--text">Nome Completo</h5>
                          <v-text-field id="name" color="black" v-model="name" required outlined></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <h5 class="text-start mb-1 blue--text">Banco</h5>
                          <v-select id="bank" color="black" v-model="idBank" :items="banks" outlined></v-select>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <h5 class="text-start mb-1 blue--text">Acordo</h5>
                        <v-select v-model="idVoting" disabled :items="votings" outlined></v-select>
                        </v-col>-->

                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <h5 class="text-start mb-1 blue--text">Email</h5>
                          <v-text-field id="email" color="black" v-model="email" required outlined></v-text-field>

                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <h5 class="text-start mb-1 blue--text">Acordo</h5>
                        <v-select v-model="idVoting" disabled :items="votings" outlined></v-select>
                        </v-col>-->
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                          <h5 class="text-start mb-1 blue--text">Data de Nascimento</h5>
                          <div class="v-text-field pt-0">
                            <the-mask
                              class="the-mask"
                              v-model="dateBirth"
                              :mask="['##/##/####']"
                              placeholder="DD/MM/AAAA"
                              id="date"
                              
                            />
                          </div>
                          <!-- <v-text-field v-model="dateBirth" required outlined></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                          <h5 class="text-start mb-1 blue--text">Funcional</h5>
                          <div class="v-text-field pt-0">
                            <the-mask
                              class="the-mask"
                              v-model="funcional"
                              placeholder="999999999"
                              :mask="['###############']"
                              id="funcional"
                            />
                          </div>
                          <!-- <v-text-field v-model="funcional" required outlined></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                          <h5 class="text-start mb-1 blue--text">Telefone:</h5>
                           <div class="v-text-field pt-0">
                            <the-mask
                              class="the-mask"
                              v-model="phone"
                              placeholder="(34) 999999999"
                              :mask="['(##) #########']"
                              id="phone"
                            />
                          </div>

                        </v-col>
                      </v-row>
                      <v-container
                        class="d-flex py-0 flex-column justify-space-between align-center"
                      ></v-container>
                    </v-col>
                  </v-container>
                </v-stepper-content>

                <!-- =================================================================
                                                      STEEP 2
                ======================================================================-->

                <v-stepper-content step="2">
                  <v-container fluid class="pa-0">
                    <!-- <v-card height="502px"> -->

                    <v-container class="d-flex justify-space-between align-center">
                      <v-spacer />
                      <v-btn
                        color="cyan darken-3"
                        v-if="file.name"
                        dark
                        class="pa-5"
                        @click="downloadFile()"
                      >
                        <v-icon left>mdi-download</v-icon>Baixar arquivo
                      </v-btn>
                      <v-spacer />
                      <!-- </v-col> -->
                    </v-container>
                    <v-card
                      class="overflow-y-auto mt-5"
                      v-if="content"
                      outlined
                      max-height="250"
                      height="250"
                    >
                      <v-card-text class="text-center" v-html="content">
                        <!-- <div v-html="content"></div> -->
                      </v-card-text>
                    </v-card>

                    <!-- </v-card> -->
                  </v-container>
                </v-stepper-content>
                <!-- =================================================================
                                                      STEEP 2
                ======================================================================-->

                <v-stepper-content step="3">
                  <v-container fluid class="pa-0">
                    <!-- <v-card height="502px"> -->

                    <v-container class="d-flex justify-space-between align-center">
                      <v-col cols="12" class="d-flex justify-center align-center">
                        <!-- <v-card class="card pa-10 align-self-center"> -->
                        <v-container
                          cols="12"
                          class="d-flex flex-column py-0 justify-center align-center"
                        >
                          <div class="text-center mb-10 blue--text" v-html="message"></div>
                          <!-- <v-col cols="12" class="d-flex justify-center align-center">
                            <v-btn color="success" dark class="pa-5" to="/">Ir para o Site</v-btn>
                          </v-col> -->
                        </v-container>
                        <!-- </v-card> -->
                      </v-col>
                    </v-container>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

            <v-container v-if="stepper !== 3">
              <v-container
                v-if="stepper === 2"
                fluid
                class="d-flex flex-column justify-center align-center pa-0"
              >
                <!-- <h2>Votação</h2> -->
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="d-flex justify-center align-center pa-0"
                >
                  <v-radio-group v-model="idVotingParam" row>
                    <v-radio
                      v-for="(element,index) in param"
                      :key="index"
                      :label="element.text"
                      :value="element.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-btn color="success" dark class="pa-5" @click="vote()">Votar</v-btn>
                </v-col>
              </v-container>
              <v-col
                cols="12"
                class="d-flex align-center"
                :class="buttonAction.next?'justify-center':'justify-center'"
              >
                <v-btn
                  text
                  @click="validadeSteppers(stepper, 'back')"
                  v-if="buttonAction.back"
                >{{buttonAction.back}}</v-btn>

                <v-btn
                  color="primary"
                  class="pa-5"
                  @click="validadeSteppers(stepper, 'next')"
                  v-if="buttonAction.next"
                >{{buttonAction.next}}</v-btn>
              </v-col>
            </v-container>
          </v-card>
        </v-col>
      </v-col>
    </v-container>
    <v-spacer />
  </v-app>
</template>

<script>
import votingService from "../../service/votingService";
import bankEmployeesService from "../../service/bankEmployeesService";

import { TheMask } from "vue-the-mask";
export default {
  name: "Votation",
  components: { TheMask },
  data: () => ({
    logo: require("@/assets/logo2.png"),
    stepper: 1,
    name: "",
    cpf: "",
    cpfIsGet: false,
    message: "",
    content: "",
    idVoting: null,
    votings: [],
    funcional: "",
    dateBirth: "",
    email: "",
    phone: "",
    closed: false,
    banks: [],
    file: {},
    idBank: "",
    overlay: false,
    snackbar: {
      text: "",
      active: false,
    },
    idVotingParam: null,
    param: [],
    buttonAction: {
      back: null,
      next: "próximo",
    },
  }),

  methods: {
    formatCpf($event) {
      if ($event.length >= 11) {
        const cpf = `'${String($event).substring(0, 11)}'`;
        this.cpfIsGet = true;
        bankEmployeesService
          .get({ cpf: cpf })
          .then((res) => {
            this.cpfIsGet = false;
            if (res.data.length > 0) {
              const data = res.data[0];

              this.name = data.name;
              this.funcional = data.funcional;
              this.dateBirth = data.date_birth;
              this.idBank = data.id_bank;
              this.email = data.email;
              this.phone = data.phone;
            }
          })
          .catch((error) => {
            this.cpfIsGet = false;
          });
      }
    },
    validateRegister() {
      this.overlay = true;

      votingService
        .get({ id: this.idVoting, cpf: `'${this.cpf}'` })
        .then((res) => {
          if (res.data.length === 0) {
            votingService.get({ id: this.idVoting }).then((res) => {
              this.content = res.data[0].content;
              this.stepper = 2;
              this.buttonAction.next = null;
              this.buttonAction.back = "retornar";
              this.overlay = false;
            });
          } else {
            this.stepper = 3;
            this.message = `<h1>Você já participou da votação.</h1>`;
            this.buttonAction.next = null;
            this.buttonAction.back = null;
            this.overlay = false;
          }
        });
    },
    validateCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    getVoting() {
      this.overlay = true;
      votingService
        .get({ id: this.id, date: new Date().getTime() })
        .then((res) => {
          if (res.data.length > 0) {
            const data = res.data[0];
            (this.votings = {
              value: data.id,
              text: data.description,
            }),
              (this.idVoting = data.id);
            this.file = {
              name: data.name_path,
              url: data.path,
            };
            this.overlay = false;
            this.closed = false;
          } else {
            this.overlay = false;
            this.stepper = 3;
            this.message = "<h1>Votação encerrada.</h1>";
          }
        });
    },
    getBankParam() {
      votingService.getBankParam({}).then((res) => {
        this.banks = res.data.bank.map((element) => {
          return { value: element.id, text: element.name };
        });
        this.param = res.data.param.map((element) => {
          return { value: element.id, text: element.title };
        });
      });
    },
    vote() {
      if(this.idVotingParam){
      const params = {
        id_voting: this.idVoting,
        id_voting_param: this.idVotingParam,
        name: String(this.name).toUpperCase(),
        cpf: this.cpf,
        funcional: this.funcional,
        email: this.email,
        phone: this.phone,
        id_bank: this.idBank,
        date_birth: this.dateBirth,
        date: new Date().getTime()
      };
      this.overlay = true;
      votingService
        .createRegisterVote(params)
        .then((res) => {
          if(res.data && res.data.result && res.data.result === 'close'){
            this.stepper = 3;
            this.message = "<h1>Votação encerrada.</h1> <br> <h4>Seu voto não foi computado.</h4>";
            this.buttonAction.next = null;
            this.buttonAction.back = null;
            this.overlay = false; 
          }else{
            this.stepper = 3;
            this.message = "<h1>Parabéns!!!</h1> <p>Seu voto foi registrado.</p>";
            this.buttonAction.next = null;
            this.buttonAction.back = null;
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.overlay = false;
          this.snackbar.active = true;
          this.snackbar.text =
            "Votação não concluida, entre em contato com o Administrador.";
        });
      }else{
         this.snackbar.active = true;
          this.snackbar.text = "Nenhuma opção escolhida."
      }
    },
    downloadFile() {
      const reportName = String(this.file.name);
      const type = /\.(csv|xls|xlsx|txt|pdf|xml|png|jpg|jpeg|gif|svg)$/i.exec(
        reportName
      )[1];

      const types = {
        pdf: "application/pdf",
        csv: "text/csv",
        xls: "application/vnd.ms-excel",
        xlsx:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        txt: "text/plain",
        xml: "application/xml",
      };

      const fileSplit = String(this.file.url).split("/");
      const file = fileSplit[fileSplit.length - 1];

      let fileData = {
        name: reportName,
        type: type,
        file: file,
      };

      this.overlay = true;
      votingService.downloadFile(fileData).then((res) => {
        let base64 = btoa(
          new Uint8Array(res.data[0].data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        let sampleArr = this.base64ToArrayBuffer(base64);
        this.saveByteArray(reportName, types[type], sampleArr);
        this.overlay = false;
      });
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },

    saveByteArray(reportName, type, byte) {
      let blob = new Blob([byte], { type: type });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    },
    validateFields() {
      if (!this.name) {
        this.snackbar.text = "Nome não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      }
      if (!this.validateCPF(this.cpf)) {
        this.snackbar.text = "CPF inválido.";
        this.snackbar.active = true;
        return false;
      }

      if (!this.idBank) {
        this.snackbar.text = "O banco não foi selecionado";
        this.snackbar.active = true;
        return false;
      }

      if (!this.funcional ) {
        this.snackbar.text = "O funcional não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      }

      const regexEmail = new RegExp(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i) 
      if (!this.email || !regexEmail.test(this.email)) {
        this.snackbar.text = "O email inválido.";
        this.snackbar.active = true;
        return false;
      }

      if (!this.phone || this.phone.length < 10) {
        this.snackbar.text = "O telefone inválido.";
        this.snackbar.active = true;
        return false;
      }

       if (!this.validateDate(this.dateBirth)) {
        this.snackbar.text = "O data de nascimento inválida.";
        this.snackbar.active = true;
        return false;
      }

      return true;
    },
    validateDate(date){
      const day = parseInt(String(date).substring(0,2))
      const month = parseInt(String(date).substring(2,4))
      const year = parseInt(String(date).substring(4))

      if((day >= 1 && day <= 31) && (month >= 1 && month <= 12) && (year >= 1900 && year <= new Date().getFullYear())){
       return true
      }

      return false
    },
    validadeSteppers(step, direction) {
      switch (step) {
        case 1:
          if (direction === "next") {
            if (this.validateFields()) {
              this.validateRegister();
            }
          }
          break;
        case 2:
          if (direction === "back") {
            this.stepper = 1;
            this.buttonAction.next = "proximo";
            this.buttonAction.back = null;
          }
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.id = this.$router.history.current.params.id;
    this.getVoting();
    this.getBankParam();
  },
};
</script>

<style lang="sass" scoped>
.the-mask
  width: 100%
  padding: 10px !important
  align-items: stretch
  min-height: 56px
  border-radius: 5px
  border: 1px solid #9e9e9e

.login
  background: #373B44
  background: linear-gradient(0deg,#2d5ead,#143263)

#app
  background: #373B44
  background: linear-gradient(0deg,#2d5ead,#143263)

@media only screen and (max-width: 600px)
  .step
    display: none

  .v-text-field__details
    display: none !important
</style>

<style lang="sass">
.v-text-field.v-text-field--enclosed .v-text-field__details
  display: none

</style>